// import api from "@/lib/api"
import Decimal from 'decimal.js-light'
import { WalletPaymentComponentMapper } from './WalletPaymentComponentMapper'

type PaymentMethodId = typeof WalletPaymentMethod.ids[keyof typeof WalletPaymentMethod.ids]

export class WalletPaymentMethod {
  static readonly ids = {
    ADVACCOUNT: 'ADVACCOUNT',
    ADVCARD: 'ADVCARD',
    PRRUB: 'PRRUB',
    QWRUB: 'QWRUB',
    YAMRUB: 'YAMRUB',
    WIRETRY: 'WIRETRY',
    P24UAH: 'P24UAH',
    MONOBUAH: 'MONOBUAH',
    LATAMEX: 'LATAMEX',
    MERCURYO: 'MERCURYO',
    CRYPTOMEMO: 'CRYPTOMEMO',
    PIXTRANZZO: 'PIXTRANZZO',
    NOKNOKPAY: 'NOKNOKPAY',
    // GROW: 'GROW',
    // LEOBANK: 'LEOBANK',
    // LEOBANK_TERMINAL: 'LEOBANK_TERMINAL',
    // XPAY: 'XPAY',
  } as const

  readonly address?: string // "5MU2gaxKVxuON7FrmQHgdEbhlUSDp9YP"
  readonly memo?: string // если статус мемо есть на манете
  readonly description: string
  // readonly fee: {
  //     fixed: Decimal
  //     flexMin: Decimal
  //     flexMax: Decimal
  //     flexPercent: Decimal
  // }
  readonly logo: string
  readonly id: number

  // ключ для формы метода (связать с формой,
  // если ключ не известный то брать дефолтную
  // форму которая для всего)
  readonly key: Ticker | PaymentMethodId

  readonly maxAmount: Decimal
  readonly minAmount: Decimal
  readonly name: string
  readonly tooltip: string
  readonly action: PaymentActionType
  readonly savedForms: ISavedWithdrawFormParams[]
  readonly isPayment: boolean
  readonly label: string

  constructor (o: IWalletPaymentMethodData) {
    this.label = o.type
    this.address = o.address ? o.address.address : ''
    this.memo = o.address ? o.address.memo : ''
    // this.system_address = o.address ? o.address.system_address : ''
    this.description = o.description
    // do not need yet
    // this.fee = {
    //     fixed: new Decimal(o.fee.fixed),
    //     flexMin: new Decimal(o.fee.flexMin),
    //     flexMax: new Decimal(o.fee.flexMax),
    //     flexPercent: new Decimal(o.fee.flexPercent),
    // }
    this.logo = o.logo || ''
    this.id = o.id
    this.key = o.key // may be just Ticker or payment method id
    this.maxAmount = new Decimal(o.maxAmount)
    this.minAmount = new Decimal(o.minAmount)
    this.name = o.name
    this.tooltip = o.tooltip
    this.action = o.action
    this.savedForms = o.savedForms || []
    this.isPayment = !!WalletPaymentMethod.ids[o.key]
  }

  get min () {
    return this.minAmount.isZero() ? 0 : +this.minAmount.toString()
  }

  get max () {
    return this.maxAmount.isZero() ? Infinity : +this.maxAmount.toString()
  }

  getComponent () {
    return WalletPaymentComponentMapper.mapPaymentToComponent(this.action, this.key)
  }
}
