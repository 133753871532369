import { Module } from 'vuex'
// import { merge } from "immutable"; //
// import { assign } from 'lodash'

import { RootState } from '@/store'

// import balances, { BalancesMap } from "./modules/balances";
import { wait, actionWrapper } from '@/lib/utils'
// import Vue from 'vue'
import finishedOrders, {
  FinishedOrdersMap,
} from '@/store/profile/finishedOrders'
import orders, { OrdersMap } from '@/store/profile/orders'
import trades, { TradesMap } from '@/store/profile/trades'
import deposits, { DepositsMap } from '@/store/profile/deposits'
import withdrawals, { WithdrawalsMap } from '@/store/profile/withdrawals'
import codes, { CodesMap } from '@/store/profile/codes'
import auth, { AuthState } from '@/store/profile/auth'
import levels, { LevelsState } from '@/store/profile/levels'

// create reactive object in order to easy use from this module
// and from component CommissionManagementBlock
// export const commission = Vue.observable({
//   useCoreToken: false,
// })

export interface ProfileState {
  fetchStatus: FetchStatus
  userSettings: GetGeneralSettingsRequest
  useCoreToken: boolean | null
}

export interface ProfileModules {
  // balances: CommonDataStruct<BalancesMap>;
  orders: CommonDataStruct<OrdersMap>
  trades: CommonDataStruct<TradesMap>
  finishedOrders: CommonDataStruct<FinishedOrdersMap>
  deposits: CommonDataStruct<DepositsMap>
  withdrawals: CommonDataStruct<WithdrawalsMap>
  codes: CommonDataStruct<CodesMap>
  auth: AuthState
  levels: LevelsState
}

const ProfileModule: Module<ProfileState, RootState> = {
  namespaced: true,
  state: () => ({
    fetchStatus: 'init',
    userSettings: {} as GetGeneralSettingsRequest,
    useCoreToken: null, // just for instant switch change
  }),
  mutations: {
    setUseToken (state, val: boolean) {
      state.useCoreToken = val
    },
    setFetchStatusProfile (state, status: FetchStatus) {
      state.fetchStatus = status
    },
    // setCustomFee (state, val) {
    //   state.userSettings.isCustomFeeEnabled = !!val
    // },
    // setGeneralSettings (state, { response }) {
    //   state.userSettings = assign({ ...state.userSettings }, response)
    //   // state.userSettings = merge(state.userSettings, userSettings);
    //   commission.useCoreToken = !!state.userSettings.isCustomFeeEnabled
    //   state.fetchStatus = 'ok'
    // },
  },
  // getters: {
  //   userSettings (state) {
  //     return state.userSettings
  //   },
  // },
  actions: {
    async updateCustomFeeEnabled ({ state, commit, dispatch }, val) {
      commit('setUseToken', val)
      commit('setFetchStatusProfile', 'loading')

      const { errors, response } = await this.$api.updateCustomFeeEnabled(
        val ? 1 : 0,
      )

      // update user because there is source of true (isCustomFeeEnabled)
      await this.$authentication.fetchUser()

      if (errors) {
        console.error(errors)
        commit('setUseToken', !val)
        commit('setFetchStatusProfile', 'error')
        // commission.useCoreToken = false // rollback switch
        await wait(111)
        commit('setFetchStatusProfile', 'ok')
        return
      }

      // commit('setCustomFee', response.customfee)
      // refresh settings
      // await dispatch('getGeneralSettings')

      commit('setFetchStatusProfile', 'ok')
    },
    // async getInitialProfileData({ state, commit, getters, dispatch, rootGetters }) {
    //     commit("setFetchStatusProfile", "loading");

    //     await Promise.all([
    //         // in order to check if user has PLC token on balance
    //         // and then show notification if he does
    //         // dispatch("fetchMainBalances", {}, { root: true }), // TODO: REMOVE
    //         dispatch('wallet/getWalletBalances', { root: true }),

    //         // dispatch("fetchFavoritePairs"),
    //         dispatch("getGeneralSettings")
    //     ]);

    //     commit("setFetchStatusProfile", "ok");
    // },
    // getGeneralSettings (...args) {
    //   return actionWrapper<ProfileState>({
    //     apiRequest: () => this.$api.getGeneralSettings(),
    //     mutationName: 'setGeneralSettings',
    //   }).bind(this)(...args)
    // },

    // eslint-disable-next-line no-empty-pattern
    async subscriptionSaveEmail ({ }, val) {
      await this.$api.subscriptionSaveEmail(val)
    },

    // eslint-disable-next-line no-empty-pattern
    async subscribeMailingListFromProfile ({ }, val) {
      await this.$api.subscribeMailingListFromProfile(val)

      // update user because there is source of true (isCustomFeeEnabled)
      await this.$authentication.fetchUser()
    },
  },
  modules: {
    // balances,
    finishedOrders,
    deposits,
    withdrawals,
    orders,
    trades,
    auth,
    levels,
    codes,
  },
}

export default ProfileModule
