import { Module } from 'vuex'
import { formatNumber, actionWrapper, wait } from '@/lib/utils'

import { WalletBalanceModel, WalletMarginBalanceModel } from '@/lib/data-models/wallet/WalletBalanceModel'
import { WalletDepositWithdrawDataModel } from '@/lib/data-models/wallet/WalletDepositWithdrawDataModel'
import { WalletSavedPaymentAddress } from '@/lib/data-models/wallet/WalletSavedPaymentAddress'
import debounce from 'lodash/debounce'
// import { GENERAL_DECIMAL_PLACES } from '@/constants'
import { RootState } from './index'

export interface WalletState {
  balances: WalletBalancesResponseItem[],
  marginBalances: IWalletMarginBalanceItem[],
  depositData: { [key in Ticker]: any } | {},
  withdrawData: { [key in Ticker]: any } | {},
  savedAddresses: any[]
  countryList: { label: string, value: string }[],
  marginFee: number,
  // marginBalanceByPair: {[key in PairName]: MarginBalanceByPair} | {}
  marginBalanceByPair: {[key in Ticker]: MarginBalanceByPair} | {}
  withdrawalLimit: any // TODO: when backend ready
  usdtSum: number
  btcSum: number

}

const WalletStateModule: Module<WalletState, RootState> = {
  namespaced: true,
  state: () => ({
    balances: [],
    marginBalances: [],
    depositData: {},
    withdrawData: {},
    savedAddresses: [],
    marginFee: 0,
    countryList: [],
    usdtSum: 0,
    btcSum: 0,

    // margin balance of selected pair on trade
    marginBalanceByPair: {},
    withdrawalLimit: null,
  }),
  actions: {
    getMarginBalance (...args) {
      return actionWrapper<WalletState>({
        // apiRequest: pair => api.getMarginBalanceByPair(pair),
        apiRequest: (tickers: Ticker[]) => this.$api.$getMarginTradeBalances(tickers),
        mutationName: 'setMarginBalancesByPair',
      }).bind(this)(...args)
    },
    getWalletMarginBalances (...args) {
      actionWrapper<WalletState>({
        apiRequest: () => this.$api.getWalletMarginBalances(),
        mutationName: 'setMarginBalances',
        cacheTime: 1000 * 60, // increase in prod
      }).bind(this)(...args)
    },
    socketUpdateMarginBalance (...args) {
      debounce(actionWrapper<WalletState>({
        apiRequest: () => this.$api.getWalletMarginBalances(),
        mutationName: 'setMarginBalances',
      }), 200).bind(this)(...args)
    },
    getWalletBalances (...args) {
      return actionWrapper<WalletState>({
        apiRequest: () => this.$api.getWalletBalances(),
        mutationName: 'setBalances',
        cacheTime: 1000 * 60, // increase in prod
      }).bind(this)(...args)
    },
    getUserWithdrawLimit (...args) {
      return actionWrapper<WalletState>({
        apiRequest: () => this.$api.getUserWithdrawLimit(),
        mutationName: 'setWithdrawalLimit',
        cacheTime: 1000 * 10,
      }).bind(this)(...args)
    },
    getCountriesList (...args) {
      return actionWrapper<WalletState>({
        apiRequest: () => this.$api.getPaymentCountriesList(),
        mutationName: 'setCountryList',
        cacheTime: 1000 * 60 * 60,
      }).bind(this)(...args)
    },
    updateWalletBalances (...args) {
      return actionWrapper<WalletState>({
        apiRequest: () => this.$api.getWalletBalances(),
        mutationName: 'setBalances',
      }).bind(this)(...args)
    },
    getWalletDepositData (...args) {
      return actionWrapper<WalletState>({
        apiRequest: ticker => this.$api.getWalletDepositData(ticker),
        mutationName: 'setDepositData',
        cacheTime: 1000 * 60, // increase in prod
      }).bind(this)(...args)
    },
    getWalletWithdrawData (...args) {
      return actionWrapper<WalletState>({
        apiRequest: ticker => this.$api.getWalletWithdrawData(ticker),
        mutationName: 'setWithdrawData',
        cacheTime: 1000 * 60, // increase in prod
      }).bind(this)(...args)
    },
    updateWalletDepositData (...args) {
      return actionWrapper<WalletState>({
        apiRequest: ticker => this.$api.getWalletDepositData(ticker),
        mutationName: 'setDepositData',
      }).bind(this)(...args)
    },
    updateWalletWithdrawData (...args) {
      return actionWrapper<WalletState>({
        apiRequest: ticker => this.$api.getWalletWithdrawData(ticker),
        mutationName: 'setWithdrawData',
      }).bind(this)(...args)
    },
    getSavedAddresses (...args) {
      return actionWrapper<WalletState>({
        apiRequest: () => this.$api.getUserPaymentAddresses(),
        mutationName: 'setSavedAddresses',
        cacheTime: 1000 * 60,
      }).bind(this)(...args)
    },
    updateSavedAddresses (...args) {
      return actionWrapper<WalletState>({
        apiRequest: () => this.$api.getUserPaymentAddresses(),
        mutationName: 'setSavedAddresses',
      }).bind(this)(...args)
    },
    // asset.update
    socketUpdateBalancesAction (...args) {
      return actionWrapper<WalletState>({
        payloadParser: (payload) => {
          const tickers = payload.reduce((acc, obj) => {
            Object.keys(obj).map(t => acc.push(t))
            return acc
          }, []) as Ticker[]
          return tickers
        },
        apiRequest: async (tickers: Ticker[]) => {
          await wait(1111)
          // console.warn('payload::', tickers)
          const responses = await Promise.all(
            tickers.map(t => this.$api.getWalletTickerBalance(t)),
          )
          return { response: responses.map(r => r.response) }
        },
        mutationName: 'updateBalances',
      }).bind(this)(...args)
    },
  },
  mutations: {
    setMarginBalancesByPair (state, { response }) {
      // state.marginBalanceByPair[payload] = response
      state.marginBalanceByPair = {
        ...state.marginBalanceByPair,
        ...response,
      }
    },
    setBalances (state, { response }) {
      state.balances = response.balances
      state.usdtSum = response.usdtSum
      state.btcSum = response.btcSum
    },
    setMarginBalances (state, { response }) {
      if (!response) return
      state.marginBalances = response.balances
      state.marginFee = response.margin_fee || 0
    },
    setWithdrawalLimit (state, { response }) {
      state.withdrawalLimit = response
    },
    setCountryList (state, { response }) {
      state.countryList = Object.keys(response.countryList).map(key => ({
        value: key,
        label: response.countryList[key],
      }))
    },
    setDepositData (state, { payload, response }) {
      state.depositData[payload] = response
      state.depositData = { ...state.depositData }
    },
    setWithdrawData (state, { payload, response }) {
      state.withdrawData[payload] = response
      state.withdrawData = { ...state.withdrawData }
    },
    setSavedAddresses (state, { response }) {
      state.savedAddresses = response
    },
    updateBalances (state, { response }) {
      response.forEach((bal: WalletBalancesResponseItem) => {
        const ind = state.balances.findIndex(b => b.ticker === bal.ticker)
        state.balances[ind] = bal

        const indMargin = state.marginBalances.findIndex(b => b.ticker === bal.ticker)

        if (state.marginBalances[indMargin]) {
          state.marginBalances[indMargin].trade_available = bal.trade_available
        }
      })

      state.balances = [...state.balances]
    },
  },
  getters: {
    getMarginBalanceByTicker: state => (ticker: Ticker): WalletMarginBalanceModel => {
      // const defaultBalance = getDefaultMarginTickerBalanceByPairModel(ticker)
      const marginBalance = state.marginBalances.find(bal => bal.ticker === ticker)
      return marginBalance ? new WalletMarginBalanceModel(marginBalance) : WalletMarginBalanceModel.getDefault(ticker)
    },
    // getMarginBalanceBuyPairAndTicker: state => (pair, ticker: Ticker) => {
    //     const defaultBalance = getDefaultMarginTickerBalanceByPairModel(ticker)
    //     const balanceFromState = (state.marginBalanceByPair[pair] || {})[ticker]
    //     return balanceFromState ? new MarginTickerBalanceByPairModel(balanceFromState) : defaultBalance
    //     // return new MarginTickerBalanceByPairModel({
    //     //     ticker,
    //     //     balanceAmount: '0',
    //     //     marginAmount: '0',
    //     //     margin_available: '0',
    //     // })
    //     // return (state.marginBalanceByPair[pair] || {})[ticker] || defaultBalance
    // },
    getMarginTickersList: state => state.marginBalances.map(balance => balance.ticker),
    getWalletMarginBalances: (state) => {
      return state.marginBalances.map(b => new WalletMarginBalanceModel(b))
    },
    balanceByTicker: state => (ticker: Ticker) => {
      const balance = state.balances.find(b => b.ticker === ticker)
      if (balance) return new WalletBalanceModel(balance)
      return WalletBalanceModel.getDefault({ ticker })
    },
    isTickerTop: state => (ticker: Ticker) => {
      const balance = state.balances.find(b => b.ticker === ticker)
      if (balance) return !!balance.is_top
      return false
    },
    getCurrencyAvailableBalance: (_, getters) => (ticker: Ticker) => {
      return getters.balanceByTicker(ticker).tradeAvailable
    },
    getCurrencyMainBalance: (_, getters) => (ticker: Ticker) => {
      return getters.balanceByTicker(ticker).mainBalance
    },
    getWalletBalances: (state) => {
      return state.balances.map(b => new WalletBalanceModel(b))
    },
    getCalculatedBalance: (state) => {
      return {
        usdtSum: formatNumber(state.usdtSum),
        btcSum: formatNumber(state.btcSum),
      }
    },
    getWalletDepositData: (state, _, rootState) => (ticker: Ticker) => {
      const deposit = state.depositData[ticker]

      if (!deposit) {
        return null
      }

      return new WalletDepositWithdrawDataModel({
        ...deposit,
        paymentList: deposit.paymentList.filter(({ key }) => {
          const paymentMethods = ['PIXTRANZZO', 'NOKNOKPAY']

          return rootState.auth.user.customer.personalNumber ? true : !paymentMethods.includes(key)
        }),
      })
    },
    getWalletWithdrawData: (state, _, rootState) => (ticker: Ticker) => {
      const withdraw = state.withdrawData[ticker]

      if (!withdraw) {
        return null
      }

      return new WalletDepositWithdrawDataModel({
        ...withdraw,
        paymentList: withdraw.paymentList.filter(({ key }) => {
          const paymentMethods = ['PIXTRANZZO', 'NOKNOKPAY']

          return rootState.auth.user.customer.personalNumber ? true : !paymentMethods.includes(key)
        }),
      })
    },
    getSavedAddresses: state => state.savedAddresses.map(
      addr => new WalletSavedPaymentAddress(addr),
    ),
  },
}

export default WalletStateModule
