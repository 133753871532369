import { ParsedUrlQueryInput, stringify } from 'querystring'

import { LendingApplicationItemInterface } from '@/data-objects/p2p/LendingApplicationItem'
import {
  DEPOSIT,
  EDR_TICKER,
  STAKING_AUTO_WITDWRAWAL_AVAILABLE,
  WITHDRAW,
} from '@/constants'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
// import { referralLink } from './helpers'
import wsService from './websocket'

import { UserNotificationModelInterface } from './data-models/p2p/UserNotificationModel'
import { OrderDescriptionModelInterface } from './data-models/p2p/OrderDescriptionModel'
// import { clearCachedData } from './utils'

export const DEFAULT_PAGE = 1
export const DEFAULT_LIMIT = 30
export const CODES_LIST_LIMIT = 30

const baseHeaders = {
  'Client-Id': process.env.CLIENT_ID || '',
  'Client-Secret': process.env.CLIENT_SECRET || '',
}

interface GeneralMutateRequest<TBody = void, TParams = {}> {
  params?: TParams
  body: TBody
}

interface PostValidate2FactorAndDevice {
  totp: string
  device_confirmation_code: string
  temp_user_id: string
}

export type Response<T = {}> = Promise<CommonResponse<T>>

export type P2PCommonResponse<T = {}> = {
  count: number
  response: Response<T>
  offset: number
}

// const CACHE = new Map()

export const createApi = (axios: NuxtAxiosInstance, cache) => ({
  getSystemParams () {
    if (!cache) return axios.get('/systemParams')
    return cache({ key: 'systemParams', expire: 1 }, () => {
      return axios.get('/systemParams')
    })

    // if (CACHE.has('/systemParams')) {
    //   return CACHE.get('/systemParams')
    // }
    // return axios.get('/systemParams')
    //   .then((res) => {
    //     CACHE.set('/systemParams', res)
    //     setTimeout(() => (CACHE.delete('/systemParams')), 1000 * 3)
    //     return res
    //   })
  },

  subscriptionSaveEmail (params) {
    return axios.post('/v2/subscription/save-email', params)
  },

  // crypto cards

  getCardApplicationStatus (): Response<getCardApplicationStatusResponse> {
    return axios.get('/api/v1/crypto-card-application/status')
  },

  createCardApplicationCard () {
    return axios.post('/api/v1/crypto-card-application')
  },

  // crypto cards end

  // NFT
  nftGetProducts (params = {}) {
    return axios.get(`/v2/nft/t-shirt?${stringify(params)}`)
  },

  nftBuy (params) {
    return axios.post('/v2/nft/order-t-shirt', params)
  },

  nftAvailable () {
    return axios.get('/v2/nft/available')
  },

  nftGetUserOrders () {
    return axios.get('/v2/nft/user-orders')
  },

  nftSaveEmail (params) {
    return axios.post('/v2/nft/save-email', params)
  },

  // airdrop
  airdropTimerConfig () {
    return axios.get('/v2/front_page/airdrop_amount')
  },

  getFrontPageSlider () {
    return axios.get('/v2/front_page/slider')
  },

  getTdxMananger () {
    return axios.get('/tdx_manager')
  },

  tdxManagerClaim (fundId) {
    return axios.post(`/tdx_manager/fund/${fundId}/claim`)
  },

  tdxManagerHistory (params: ParsedUrlQueryInput) {
    return axios.get(
      `/tdx_manager/fund/${params.fundId}/history?${stringify(params)}`,
    )
  },

  // fundmanager
  getVesting () {
    return axios.get('/fundmanager')
  },

  getVestingFundsByTicker (ticker) {
    return axios.get(`/fundmanager/funds/${ticker}`)
  },

  vestingClaim (fundId) {
    return axios.post(`/fundmanager/fund/${fundId}/claim`)
  },

  getVestingHistory (params: ParsedUrlQueryInput) {
    return axios.get(`/fundmanager/fund/${params.fundId}/history?${stringify(params)}`)
  },

  // burning stage
  getBurningStageList (): Response<IBurningStageResponseItem[]> {
    return axios.get('/v2/burning-list')
  },

  activateBurningStage (id): Response<any> {
    return axios.post('/v2/burning-activate', { id })
  },

  sendSelectedFiat ({ hash, paymentMethodId }): Response<any> {
    return axios.post('/makefiatpayment', {
      invoice: hash,
      paymentMethodId,
    })
  },

  getConfirmInvoice (invoice: string): Response<any> {
    return axios.get(`/confirminvoice/${invoice}`)
  },

  // OTC //////////////////
  otcAdminGetQuotation (id): Response<otcEditQuotationResponse> {
    return axios.get(`/otc/admin/getotc/${id}`)
  },

  otcAdminGetOrderHistory (page = 1, status?: string): Response<any> {
    const params = status ? `page=${page}&${status}` : `page=${page}`
    return axios.get(`/otc/admin/orderhistory?${params}`)
  },

  otcAdminUpdateQuotation ({ id, data }): Response<any> {
    return axios.post(`/otc/admin/editotc/${id}`, data)
  },

  otcAdminGetPaymentList (): Response<OtcPaymentMethod> {
    // Список платежных методов для выбора
    return axios.get('/otc/admin/paymentslist')
  },

  otcAdminCreateOtc (pld): Response<any> {
    // форма бай сел + все платежные методы
    return axios.post('/otc/admin/createotc', pld)
  },

  otcAdminEditOtc (): Response<any> {
    // форма бай сел + все платежные методы
    return axios.post('/otc/admin/editotc')
  },

  otcAdminBalances (): Response<any> {
    // Статистика доступных балансов ОТС в криптовалюте
    // Фиатные балансы
    return axios.get('/otc/admin/balances')
  },

  otcGetCurrencies (): Response<otcCurrenciesResponse> {
    return axios.get('/otc/currencylist')
  },

  otcSaveAdminQuatations (params): Response<any> {
    return axios.post('/otc/admin/updatebalance', params)
  },

  otcGetQuotationsList (): Response<any> {
    return axios.get('/otc/allotclist')
  },

  otcGetActiveQuotationsList (): Response<any> {
    return axios.get('/otc/admin/activeotclist')
  },

  otcCreateOrder (params): Response<any> {
    return axios.post('/otc/createorder', params)
  },

  otcConfirmOrder (id): Response<any> {
    return axios.post(`/otc/confirmorder/${id}`)
  },

  otcCancelOrder (id): Response<any> {
    return axios.post(`/otc/cancelorder/${id}`)
  },

  otcUserGetQuotation (id): Response<otcEditQuotationResponse> {
    return axios.get(`/otc/getotc/${id}`)
  },

  otcUserGetOrdersList (page = 1, status?: string): Response<any> {
    const params = status ? `page=${page}&${status}` : `page=${page}`
    return axios.get(`/otc/orderhistory?${params}`)
  },

  otcConfirmPayOrder (id): Response<any> {
    return axios.post(`/otc/confirmpayorder/${id}`)
  },

  otcAdminGetQuotationOrders (id, page = 1, status?: string): Response<any> {
    const params = status ? `page=${page}&${status}` : `page=${page}`
    return axios.get(`/otc/admin/orderhistory/${id}?${params}`)
  },

  otcAdminConfirmPayOrder (id): Response<any> {
    return axios.post(`/otc/admin/confirmpayorder/${id}`)
  },

  otcAdminConfirmOrder (id): Response<any> {
    return axios.post(`/otc/admin/confirmorder/${id}`)
  },

  otcAdminCancelOrder (id): Response<any> {
    return axios.post(`/otc/admin/cancelorder/${id}`)
  },

  // P2P //////////////////
  p2pGetActualCurrencyBalance (): Promise<any> {
    return axios.get('/p2p/actual-currency-balance')
  },

  p2pCreateOrder (
    params: LendingApplicationItemInterface = undefined,
  ): Response<any> {
    return axios.get(
      `/p2p/create-order?${stringify(
        (params as unknown) as ParsedUrlQueryInput,
      )}`,
    )
  },

  p2pTakeCredit (id: number, params: TakeCreditFormInterface): Response<any> {
    return axios.get(
      `/p2p/take-credit/${id}?${stringify(
        (params as unknown) as ParsedUrlQueryInput,
      )}`,
    )
  },

  p2pOrderDescription (
    loanId: number,
  ): Response<OrderDescriptionModelInterface> {
    return axios.get(`/p2p/order-description/${loanId}`)
  },

  p2pOrderRequest (loanId: number): Response<OrderDescriptionModelInterface> {
    return axios.get(`/p2p/order-requests/${loanId}`)
  },

  p2pLendSearch (params: lendSearchParamsInterface = undefined): Response<any> {
    return axios.get(
      `/p2p/lend-search?${stringify(
        (params as unknown) as ParsedUrlQueryInput,
      )}`,
    )
  },

  p2pLendUser (
    params: LendingApplicationItemInterface = undefined,
  ): Response<any> {
    return axios.get(
      `/p2p/lend-user?${stringify((params as unknown) as ParsedUrlQueryInput)}`,
    )
  },

  p2pBorrowUser (): Response<any> {
    return axios.get('/p2p/borrow-user')
  },

  p2pBorrowRequest (
    loanId: string | number,
    action: LoanRequestActionType,
  ): Response<any> {
    return axios.post(`/p2p/borrow-request/${loanId}`, { action })
  },

  p2pCancelBorrowRequest (
    loanId: string | number,
    action: LoanRequestActionType,
  ): Response<any> {
    return axios.post(`/p2p/cancel-borrow-request/${loanId}`, { action })
  },

  p2pDeleteBorrowRequest (loanId: string | number): Response<any> {
    return axios.post(`/p2p/delete-borrow-request/${loanId}`)
  },

  p2pUserNotifications (): Response<UserNotificationModelInterface[]> {
    return axios.get('/p2p/user-notification')
  },

  // p2pCancelOrderProposal(loanId: number | string): Response<any> {
  //     return axios.get(`/p2p/loan-cancel/${loanId}`)
  // },

  p2pUserStatManagement (): Response<any> {
    return axios.get('/p2p/user-stat-management')
  },

  p2pActiveOrders (): Response<any> {
    return axios.get('/p2p/active-order')
  },

  p2pDealsHistory (): Response<any> {
    return axios.get('/p2p/deals-history')
  },

  p2pShowMoreLend (params: ShowMoreParamsInterface): Response<any> {
    return axios.get(
      `/p2p/show-more-lend?${stringify(
        (params as unknown) as ParsedUrlQueryInput,
      )}`,
    )
  },

  // p2pShowMoreBorrow(params: ShowMoreParamsInterface): Response<any> {
  //     return axios.get(`/p2p/show-more-borrow?${stringify(params as unknown as ParsedUrlQueryInput)}`)
  // }
  // P2P //////////////////

  // new P2P //////////////////

  p2pDeleteLoanRequest (id) {
    return axios.post(`/p2p/delete-loan-request/${id}`)
  },

  p2pCurrencyList (): Response<P2PCurrency[]> {
    return axios.get('/p2p/currency-list')
  },

  getP2PLoansList (params = {}): Response<P2PLoanResponse> {
    return axios.get(`/p2p/loan-request-list?${stringify(params)}`)
  },

  getP2PMyLoansList (params = {}): Response<P2PLoanResponse[]> {
    return axios.get(`/p2p/my-loan-request-list?${stringify(params)}`)
  },

  getP2PMyApprovedBorrowList (params = {}): Response<P2PLoanHistoryResponse[]> {
    return axios.get(`/p2p/my-borrow-list?${stringify(params)}`)
  },

  getP2PMyRequestedBorrowList (params = {}): Response<P2PLoanHistoryResponse[]> {
    return axios.get(`/p2p/my-borrow-request-list?${stringify(params)}`)
  },

  getP2PBorrowDetails (id, params = {}): Response<P2PLoanHistoryResponse[]> {
    return axios.get(`/p2p/borrow-request-list/${id}?${stringify(params)}`)
  },

  getP2PTotalData (): Response<Hash<P2PLoansStatResponse>> {
    return axios.get('/p2p/total-data')
  },

  getP2PBorrowsList (params = {}): Response<P2PLoanHistoryResponse[]> {
    return axios.get(`/p2p/borrow-request-list?${stringify(params)}`)
  },

  // getP2PMyLoanList(params = {}): Response<any> {
  //     return axios.get(`/p2p/my-loan-list?${stringify(params)}`)
  // },

  createBorrowRequest (pld): Response<NewP2PLoanResponse> {
    return axios.post('/p2p/create-borrow-request', pld)
  },

  createLoanRequest (pld): Response<P2PLoanResponse> {
    return axios.post('/p2p/create-loan-request', pld)
  },

  updateLoanRequest ({ id, data }): Response<NewP2PLoanResponse> {
    return axios.post(`/p2p/update-loan-request/${id}`, data)
  },

  acceptLoanRequest (id): Response<P2PLoanHistoryResponse> {
    return axios.post(`/p2p/accept-borrow-request/${id}`)
  },

  repayLoan (id): Response<P2PLoanHistoryResponse> {
    return axios.post(`/p2p/finish-loan/${id}`)
  },

  rejectLoanRequest (id): Response<P2PLoanHistoryResponse> {
    return axios.post(`/p2p/reject-borrow-request/${id}`)
  },

  // p2pMyLoansList(): Response<any> {
  //     return axios.get(`/my-loan-request-list`)
  // }

  getP2PLoanValidationLimits (id: number) {
    return axios.get(`/p2p/loan-validation-limits/${id}`)
  },

  // new P2P //////////////////

  getInvestHistory (): Response<any> {
    return axios.get('/v2/investbox-investmenthistory')
  },

  closeMyInvestment (id): Promise<any> {
    return axios.post('/v2/staking-closeinvest', { id })
  },

  getMyInvestments (): Response<investBoxMyInvestmentResponseData> {
    return axios.get('/v2/investbox-myinvestment')
  },

  postInvestmentOnInvestBox ({
    amount,
    investBoxId,
  }: {
    amount: number
    investBoxId: number
  }): Response {
    return axios.post('/v2/staking-makeinvest', {
      amount,
      investbox_id: investBoxId,
    })
  },

  getInvestBoxInvestPlan (): Response<investBoxInvestPlanResponseData> {
    return axios.get('/v2/investbox-list')
  },

  getStakingPlansByTicker (ticker: Ticker): Response<StakingPlanResponseItem[]> {
    return axios.get(`/v2/staking-plans/${ticker}`)
  },

  getStakingPlanHistory () {
    // return axios.get('/v2/staking-plans/BTC') // temp
    return axios.get('/v2/staking-history') // temp
  },

  getStakingPlanDetails (id) {
    return axios.get(`/v2/staking-data/${id}`)
  },

  getStakingPlansTickers (): Response<any[]> {
    return axios.get('/v2/staking-tickers')
  },

  getStakingHistoryTickers (): Response<any[]> {
    return axios.get('/v2/staking-history-tickers')
  },

  getStakingGroup (): Response<StakingGroupResponseData> {
    return axios.get('/v2/staking-groups')
  },

  setAutoCancel ({ autoCancel, id }): Response<any> {
    if (!STAKING_AUTO_WITDWRAWAL_AVAILABLE) return
    return axios.post('/v2/staking-setautocancel', {
      autoCancel: autoCancel ? 1 : 0,
      id,
    })
  },

  // TRADING COMPETITION
  // /v2/tr-competitions-groups
  getCompetitionGroups (): Promise<TradingCompetitionGroupsResponseItem[]> {
    return axios.get('/v2/tr-competitions-groups')
  },

  // /v2/tr-competitions-plans/{ticker}
  getCompetitionPlansForTicker (
    ticker: Ticker,
  ): Promise<TradingCompetitionPlanResponseItem> {
    return axios.get(`/v2/tr-competitions-plans/${ticker}`)
  },

  // /v2/tr-competitions-data/{id}
  getCompetitionData (id: number): Promise<TradingCompetitionDataResponse> {
    return axios.get(`/v2/tr-competitions-data/${id}`)
  },

  // /v2/tr-competitions-history
  getCompetitionHistory (): Promise<TradingCompetitionPlanResponseItem> {
    return axios.get('/v2/tr-competitions-history')
  },

  participateInTradingCompetition (id: string): Response<any> {
    return axios.post('/v2/tr-competitions-takeplace', { id })
  },

  postByTokens (body): Response<any> {
    return axios.post('/v2/ieo_buy_token', body)
  },

  getIeoItem (slug, locale = 'en'): Response<any> {
    return axios.get(`/v2/ieo/${slug}?lang=${locale}`)
  },

  getIeoList (locale = 'en'): Response<any> {
    return axios.get(`/v2/ieo-list?lang=${locale}`)
  },

  getLocale (): Response<any> {
    return axios.get('/v2/locale', {
      headers: { ...baseHeaders, 'Accept-Encoding': 'gzip, deflate' },
    })
  },

  getTweets (): Response<{ data: Tweet[] }> {
    return axios.get('/v2/tweets')
  },

  // getNews (): Promise<any> {
  //   return fetch('https://news.coinsbit.io/wp-json/endpoint/latest-posts')
  //     .then(response => response.json())
  //     .then(jsonResponse => ({
  //       response: JSON.parse(jsonResponse),
  //       errors: null,
  //       status: 201,
  //     }))
  //     .catch((error) => {
  //       return Promise.resolve({
  //         response: null,
  //         errors: error,
  //         status: 422,
  //       })
  //     })
  // },

  getPartners (): Response<getFrontPageResponse> {
    return axios.get('/v2/front_page/partners')
  },

  getMarketCap (): Response<CoinMarketCap[]> {
    return axios.get('v2/front_page/coins-market-cup')
  },

  postLocale (locale: string): Response<any> {
    return axios.post(`/v2/locale?lang=${locale}`)
  },

  getFrontCurrencies (): Response<getFrontPageResponse> {
    return axios.get('/v2/front_page/currencies')
  },

  getBattleNews (): Response<getFrontPageResponse> {
    return axios.get('/v2/front_page/news')
  },

  getListing (): Response<any> {
    return axios.get('/v2/front_page/digest')
  },

  /**
   * Auth routes
   */
  confirmAccount (token: string): Response {
    return axios.post(`/v2/register/apiconfirmation/${token}`)
  },

  resendDeviceCode (body): Response {
    return axios.post('/api/v1/auth/login/resend-device-code', body)
  },

  registerResendCode (body): Response {
    return axios.post('/api/v1/auth/register/resend-email-confirm-code', body)
  },

  registerConfirmation (body): Response {
    return axios.post('/api/v1/auth/register/confirm-email-by-code', body)
  },

  deviceConfirmation (body): Response {
    return axios.post('/api/v1/auth/login/validate-device-by-codes', body, {
      headers: baseHeaders,
    }) // deviceConfirmation
  },

  logout (): Response {
    return axios.post('/api/v1/auth/logout')
  },

  register (body: PostRegisterRequest): Response {
    return axios.post('/api/v1/auth/register', body)
  },

  validate2Factor (
    body: PostValidate2Factor,
  ): Response<{ token_web_socket: string; locale: string }> {
    return axios.post('/v2/2fa/validateapi', body, { headers: baseHeaders }) // validate2Factor
  },

  validateDeviceAndTwoFa (body: PostValidate2FactorAndDevice) {
    return axios.post('api/v2/2fa/validate', body, { headers: baseHeaders }) // validateDeviceAndTwoFa
  },

  // validateDevice(
  //     body: { device_confirmation_code: string}
  // ): Response<{ token_web_socket: string; locale: string }> {
  //     return axios.post(`/v2/deviceconfirmation/validate`, body)
  // }

  postForgotPassword (body: PostForgotPasswordRequest): Response {
    return axios.post('/v2/passwordapi/email', body)
  },

  // postResetPassword (
  //   body: PostResetPasswordRequest,
  // ): Response<{ token_web_socket: string; locale: string }> {
  //   return axios.post('/v2/password/reset', body)
  // },

  postPasswordCodeSend (body: PostForgotPasswordRequest): Response {
    return axios.post('/api/v1/auth/forgot/send-email-with-code', body)
  },

  postPasswordCodeResend (body: any): Response<any> {
    return axios.post('/api/v1/auth/forgot/resend-email-with-code', body)
  },

  postPasswordCodeValidate (body: any): Response<any> {
    return axios.post('/api/v1/auth/forgot/validate-code', body)
  },

  // ----- Settings
  getGeneralSettings (): Response<GetGeneralSettingsRequest> {
    return axios.get('/v2/settings/general')
  },

  subscribeMailingListFromProfile (params) {
    return axios.post('/v2/settings/subscribe', params)
  },

  getCommonTimes (): Response<string[]> {
    return axios.get('/v2/common/logoutTimes')
  },

  getSecuritySettings (): Response<GetSecuritySettings> {
    return axios.get('/v2/apisettings/security')
  },

  // getPasswordScore (params): Response<{ score: number }> {
  //   return axios.get(`/v2/password/validate?${stringify(params)}`)
  // },

  getSessions (): Response<{ sessions: Session[] }> {
    return axios.get('/v2/apisettings/session')
  },

  updateCustomFeeEnabled (val: number): Response<any> {
    return axios.post('/v2/settings/customfee', {
      customfee: val,
    })
  },

  getApiKeys (): Response<ApiPair[]> {
    return axios.get('/v2/api_key')
  },

  generateNewApiKey (): Response<ApiPair> {
    return axios.post('/v2/api_key')
  },

  toggleApiKey (id: string): Response<ApiPair> {
    return axios.post(`/v2/api_key/${id}`)
  },

  deleteApiKey (id: string): Response {
    return axios.delete(`/v2/api_key/${id}`)
  },

  getGenerateNewFactorSecret (): Response<GetNewFactorSecret> {
    return axios.post('/v2/apisettings/2fa/generate')
  },

  postGeneralSettings ({
    body,
  }: GeneralMutateRequest<PostGeneralSettingsRequest>): Response {
    return axios.post('/v2/settings/general', body)
  },

  postPasswordChange ({
    body,
  }: GeneralMutateRequest<PostPasswordRequest>): Response {
    return axios.post('/v2/settings/apipassword', body)
  },

  postToggle2fa ({ body }: GeneralMutateRequest<PostToggle2fa>): Response {
    return axios.post('/v2/apisettings/2fa/toggle', body)
  },

  postTerminateSession ({ id }: { id: string }): Response {
    return axios.delete(`/v2/apisettings/session/${id}`, {})
  },

  // Delete account of user
  deleteAccount (): Response<any> {
    return axios.post('v2/settings/make-delete-account')
  },

  confirmDeleteAccount (body): Response<any> {
    return axios.post('v2/settings/confirm-delete-account', body)
  },

  getReferralSettings (): Response<ReferralSettings> {
    return axios.get(
      '/v2/settings/referral',
    ) /* .then(result => referralLink(result)) */
  },

  getReferrals (params: ParsedUrlQueryInput): Response<ReferralRow[]> {
    return axios.get(`/v2/settings/referral/referrals?${stringify(params)}`)
  },

  getActualHistory (
    params: ParsedUrlQueryInput,
  ): Response<ReferralHistoryRow[]> {
    return axios.get(
      `/v2/settings/referral/actual_history?${stringify(params)}`,
    )
  },

  getReferralStatistics (): Response<ReferralStatistics> {
    return axios.get('/v3/apiprofile/referral/trade/get-statistic')
  },

  postReferralClaim () {
    return axios.post('/v3/apiprofile/referral/trade/make-claim')
  },

  checkReferralCode ({ id }) {
    return axios.post('/api/v2/referral', { referral_code: id })
  },

  // ----- /Settings

  // ----- /Referral Games

  getReferralGames (): Response<ReferralGame> {
    return axios.get('/v2/hunger_games/current_game')
  },

  postActivateGame (): Response<{
    my_data: MyReferralData
    top_players: TopPlayers
  }> {
    return axios.post('/v2/hunger_games/activate_user')
  },

  // ----- /Referral Games

  // ----- FYC
  cancelKyc () {
    return axios.post('/v2/kyc/cancel')
  },

  getKycStatus (): Response<GetKycStatusRequest> {
    return axios.get('/v2/kyc/status')
  },

  getPassport (): Response<GetKycPassportRequest> {
    return axios.get('/v2/kyc/passport')
  },

  postPassport ({
    body,
  }: GeneralMutateRequest<GetKycPassportRequest>): Response {
    return axios.post('/v2/kyc/passport', body)
  },

  getDocuments (): Response<GetKycDocumentsRequest> {
    return axios.get('/v2/kyc/documents')
  },

  postDocuments ({ body }: GeneralMutateRequest<{}>): Response {
    return axios.post('/v2/kyc/documents', body)
  },

  kycVerification ({ body }): Response {
    return axios.post('/v2/kyc/verification', body)
  },

  // ----- /Kyc

  // AUTO KYC
  initAutoKycVerification () {
    return axios.post('/api/v1/user/web-auto-kyc')
  },

  autoKycVerification (params: { locale?: string } = {}) {
    return axios.post('/api/v1/user/auto-kyc', params)
  },

  postKycInfo (formData, url: string) {
    return axios.post(url, formData, {
      withCredentials: false,
    })
  },

  postKycDocuments (formData, url: string) {
    return axios.post(url, formData, {
      withCredentials: false,
    })
  },

  postKycSubmit (url: string) {
    return axios.post(url, undefined, {
      withCredentials: false,
    })
  },

  postKycSelfie (selfie: File, url: string) {
    const formData = new FormData()
    formData.append('face', selfie)
    return axios.post(url, formData, {
      withCredentials: false,
    })
  },

  // ----- Support
  getTicketsList (
    params: ParsedUrlQueryInput,
  ): Response<{ records: GetTicketsListResponse[]; total: string }> {
    return axios.get(`/v2/support?${stringify(params)}`)
  },

  postTicket ({ body }: GeneralMutateRequest<PostTicketRequest>): Response {
    return axios.post('/v2/support', body)
  },

  applyToList ({ body }: GeneralMutateRequest<GetApplyToList>): Response {
    return axios.post('/v2/applyToList', body)
  },

  applyToPartnership ({ body }: GeneralMutateRequest<GetApplyToList>): Response {
    return axios.post('/v2/applyToPartnership', body)
  },

  deleteTicket ({ ticketId }: { ticketId: number }): Response {
    return axios.delete(`/v2/support/${ticketId}`)
  },

  getTicketInfo ({ ticketId }: { ticketId: number }): Response<Ticket> {
    return axios.get(`/v2/support/${ticketId}`)
  },

  postComment ({
    params,
    body,
  }: GeneralMutateRequest<{ text: string }, { id: string }>): Response {
    return axios.post(`/v2/support/comment/${params && params.id}`, body)
  },

  getLanguages (): Response<Languages[]> {
    return axios.get('/v2/common/languages')
  },

  getSupportCategory (): Response {
    return axios.get('/v2/support/category')
  },

  getTicketSatuses (): Response {
    return axios.get('/v2/support/status')
  },

  postStatus ({
    params,
    body,
  }: GeneralMutateRequest<{ status: string }, { id: string }>): Response {
    return axios.post(`/v2/support/status/${params && params.id}`, body)
  },

  // ----- /Support

  // Levels

  getLevelsTicketInfo (): Response<any> {
    return axios.get('v2/ticket/info')
  },

  getLevels (): Response<any> {
    return axios.get('v2/ticket/levels')
  },

  makeLevelsTicketOrder (): Response {
    return axios.post('v2/ticket/order')
  },

  increaseLevel (body): Response {
    return axios.post('v2/ticket/points/distribution', body)
  },

  // ----- FAQ
  getFaqList (params: {
    language: string
    lang: string
  }): Response<{ groups: FaqGroup[] }> {
    const path = `/v2/faq?${stringify(params)}`
    if (!cache) return axios.get(path)
    return cache({ key: path, expire: 120 }, () => {
      return axios.get(path)
    })
  },

  getFaqListForWelcome (params: {
    language: string
    lang: string
  }): Response<{ groups: FaqGroup[] }> {
    return axios.get(`/v2/faq?${stringify(params)}`)
  },

  // ----- CHAT
  sendMessage ({
    params,
    body,
  }: GeneralMutateRequest<
    { message: string },
    { languageCode: string }
  >): Response {
    return axios.post(`/chat/send_message/${params!.languageCode}`, body, {
      headers: baseHeaders,
    })
  },

  getMessages ({
    languageCode,
  }: {
    languageCode: string
  }): Response<ResponseMessage> {
    return axios.get(`/chat/get_messages/${languageCode}`)
  },

  getCurrencyFeeByMethod ({
    ticker,
    method,
    type,
  }: GetCurrencyFeeByMethodParams): Response<GetCurrencyFeeByMethodResponse> {
    const params = {
      transaction_method: method === 'withdraw' ? WITHDRAW : DEPOSIT,
      type,
    }

    return axios.get(`/v2/currency-fee/${ticker}?${stringify(params)}`)
  },

  getCurrencyFee (): Response<GetCurrencyFeeResponse> {
    return axios.get('/v2/fee-schedule')
  },

  withdrawChange (body): Response<any> {
    return axios.post('v2/profile/withdrawfchange', body)
  },

  postWithdrawRequest ({
    body,
  }: GeneralMutateRequest<PostWithdrawBody>): Response<PostWithdrawResponse> {
    return axios.post('/v2/profile/withdraw', body)
  },

  postWithdrawRequestWith2FA ({
    body,
  }: GeneralMutateRequest<PostWithdraw2FaBody>): Response {
    return axios.post('/v2/profile/withdraw/2fa', body)
  },

  withdrawKitVisa (body): Response {
    return axios.post('/v2/profile/withdrawkitvisa', body)
  },

  withdrawAdvVisa (body): Response {
    return axios.post('/v2/profile/withdrawadvvisa', body)
  },

  resendWithdrawConfirmCode (transactionId): Response {
    return axios.get(`/v3/balance/resendConfirmationCode/${transactionId}`)
  },

  getDepositFiatBodyRequest (
    params: ParsedUrlQueryInput,
  ): Response<PostDepositUsdBody> {
    return axios.get(
      `/v2/balance/deposit-${(params.ticker as string).toLowerCase()}?${stringify(
        params,
      )}`,
    )
  },

  getDepositMercuryoBodyRequest (
    params: ParsedUrlQueryInput,
  ): Response<PostDepositUsdBody> {
    return axios.get(`/v2/balance/deposit-mercuryo?${stringify(params)}`) // /deposit-mercuryo?ticker=USD&amount=700
  },

  getDepositFChangeBodyRequest (
    params: ParsedUrlQueryInput,
  ): Response<PostDepositUsdBody> {
    // delete params.currency_type
    return axios.get(`/v2/balance/deposit-fchange?${stringify(params)}`)
  },

  getDepositLatamexBodyRequest (
    params: ParsedUrlQueryInput,
  ): Response<PostDepositUsdBody> {
    // delete params.currency_type
    return axios.get(`/v2/balance/deposit-latamex?${stringify(params)}`)
  },

  //  getDepositEurBodyRequest(
  //     params: ParsedUrlQueryInput
  // ): Response<PostDepositUsdBody> {
  //     return axios.get(`/v2/balance/deposit-eur?${stringify(params)}`)
  // }

  //  getDepositUsdBodyRequest(
  //     params: ParsedUrlQueryInput
  // ): Response<PostDepositUsdBody> {
  //     return axios.get(`/v2/balance/deposit-usd?${stringify(params)}`)
  // }

  getDepositBodyUsdRequestVisaMC (
    params: ParsedUrlQueryInput,
  ): Response<PostDepositUsdBody> {
    return axios.get(`/v2/balance/deposit-hamsterpay?${stringify(params)}`)
  },

  getDepositBodyEurRequestVisaMC (
    params: ParsedUrlQueryInput,
  ): Response<PostDepositUsdBody> {
    return axios.get(`/v2/balance/deposit-hamsterpay-eur?${stringify(params)}`)
  },

  getPaymentCountriesList (): Response<{
    countryList: { [key in string]: string }
  }> {
    return axios.get('/v3/profile/countrylist')
  },

  getWalletBalances (): Response<{
    balances: WalletBalancesResponseItem[]
    btcSum: number
    excSum: string
    usdtSum: number
  }> {
    return axios.get('/v3/apiprofile/balance')
  },

  getWalletMarginBalances (): Response<{
    balances: WalletBalancesResponseItem[]
    margin_fee: number
  }> {
    return axios.get('/v3/profile/margin-balance')
  },

  getWalletTickerBalance (ticker: Ticker): Response<WalletBalancesResponseItem> {
    return axios.get(`/v3/profile/balance/${ticker}`)
  },

  getWalletDepositData (ticker: Ticker): Response<IWalletDepositWithdrawData> {
    return axios.get(`/v3/profile/depositdata/${ticker}`)
  },

  getWalletWithdrawData (ticker: Ticker): Response<IWalletDepositWithdrawData> {
    return axios.get(`/v3/profile/withdrawaldata/${ticker}`)
  },

  getUserWithdrawLimit (): Response<{
    limit: number
    max: number
  }> {
    return axios.get('/v3/profile/withdraw_kyc_limit')
  },

  sendDepositFormRequest ({
    ticker,
    paymentKey,
    data,
  }): Response<{ [key in string]: string } & { redirectLink: string }> {
    return axios.post(`/v3/balance/deposit-${ticker}/${paymentKey}`, data)
  },

  sendWithdrawalRequest ({ ticker, paymentKey, data }): Response<any> {
    return axios.post(`/v3/balance/withdrawal-${ticker}/${paymentKey}`, data)
  },

  getWalletCalculateFee ({
    ticker,
    paymentKey,
    amount,
    action,
  }: {
    ticker: Ticker
    paymentKey: string
    amount: string | number
    action: PaymentActionType
  }): Response<{ fee: string; total: string }> {
    return axios.post(`/v3/balance/calcfee-${ticker}/${paymentKey}`, {
      amount,
      action,
    })
  },

  getUserPaymentAddresses (): Response<IAddressManagementItem[]> {
    return axios.get('/v3/apiprofile/addresses-management')
  },

  deleteUserPaymentAddress (id: number): Response<any> {
    return axios.get(`/v3/profile/delete-addresses-management/${id}`)
  },

  postTransfer ({
    body,
  }: GeneralMutateRequest<{
    ticker: Ticker
    amount: string
    transfer_method: TransferMethod
  }>): Response<{ mainBalance: string; tradeBalance: string }> {
    return axios.post('/v2/profile/transfer', body)
  },

  postTransferSpot ({
    body,
  }: GeneralMutateRequest<{
    ticker: Ticker
    amount: string
    transfer_method: TransferMethod
  }>): Response<{ mainBalance: string; tradeBalance: string }> {
    return axios.post('/v2/profile/transfer', body)
  },

  postTransferMargin ({
    body,
  }: GeneralMutateRequest<{
    ticker: Ticker
    amount: string
    transfer_method: TransferMethod
  }>): Response<{ mainBalance: string; tradeBalance: string }> {
    return axios.post('/v2/profile/transfer-margin', body)
  },

  postTransferMarginMethodBorrow ({
    body,
  }: GeneralMutateRequest<{
    ticker: Ticker
    amount: string
    percent: string
  }>) {
    return axios.post('/v2/profile/transfer-borrow', body)
  },

  postTransferMarginMethodRepay ({
    body,
  }: GeneralMutateRequest<{
    ticker: Ticker
    amount: string
    percent: string
  }>) {
    return axios.post('/v2/profile/transfer-repay', body)
  },

  postMarginCalculator ({
    body,
  }: GeneralMutateRequest<{
    side: 'buy' | 'sell'
    entry_price: string
    exit_price: string
    amount: string
  }>) {
    return axios.post('/v2/margin-calculator', body)
  },

  getTradeSettings (): Response<{
    fee: number
    fee_plc: number
    fee_plc_buy: number
  }> {
    return axios.get('/v2/trade/settings')
  },

  getCurrencyBlockHeight ({
    ticker,
  }: {
    ticker: Ticker
  }): Response<{ height: number | string }> {
    return axios.get(`/v2/blockHeight/${ticker}`)
  },

  getCurrencyAddress ({
    ticker,
  }: {
    ticker: Ticker
  }): Response<{ address: string | null }> {
    return axios.get(`/v2/profile/address/${ticker}`)
  },

  postCurrencyAddress ({
    body,
  }: GeneralMutateRequest<{
    ticker: Ticker
    system_address: String
  }>): Response<{ address: string; system_address?: string }> {
    return axios.post('/v2/profile/address', body)
  },

  /**
   * EDR specific requests
   * */
  getEDRAddress (): Response<{ account: string; memo: string | null }> {
    return axios.get('/v2/edr/address')
  },

  createEDRAddress (): Response<{ memo: string }> {
    return axios.post('/v2/edr/address')
  },

  postWithdrawEDR ({
    body,
  }: GeneralMutateRequest<Partial<PostWithdrawBody>>): Response<
    PostWithdrawResponse
  > {
    return axios.post('/v2/edr/withdraw', { ...body, ticker: EDR_TICKER })
  },

  /**
   * END EDR specific requests
   * */

  getProfileBalances (): Response<RawBalance[]> {
    return axios.get('/v2/profile/balance')
  },

  // ----- Codes
  getMyCodes ({
    ticker,
    ...params
  }: ParsedUrlQueryInput): Response<Orderlist<RawCode>> {
    return axios.get(
      `/v2/profile/codes/my${ticker && '/' + ticker}?${stringify(params)}`,
    )
  },

  getHistoryCodes ({
    ticker,
    ...params
  }: ParsedUrlQueryInput): Response<Orderlist<RawCode>> {
    return axios.get(
      `/v2/profile/codes/history${ticker && '/' + ticker}?${stringify(params)}`,
    )
  },

  postCreateCode ({
    body,
  }: GeneralMutateRequest<{
    ticker: Ticker
    amount: string
  }>): Response {
    return axios.post('/v2/profile/code/create/by_code', body)
  },

  postCreateCodeConfirmation (body: any): Response {
    return axios.post('/v2/profile/code/confirmation', body)
  },

  resendCreateCodeConfirmation (body: any): Response {
    return axios.post('/v2/profile/code/resend', body)
  },

  // postCreateCode ({
  //   body,
  // }: GeneralMutateRequest<{
  //   ticker: Ticker
  //   amount: string
  // }>): Response {
  //   return axios.post('/v2/profile/code/create', body)
  // },

  // postCreateCodeConfirmation (body): Response {
  //   return axios.post('//v2/apiprofile/code/confirmWithdraw', body)
  // },

  // resendCreateCodeConfirmation (body): Response {
  //   return axios.post('/v2/apiprofile/code/resendConfirmationCode/', body)
  // },

  getGeetestCaptchaParamsFrom () {
    return axios.get('/v2/common/startcaptchaservlet')
  },

  setReferralForMobileApp () {
    return axios.post('/v2/common/setreferalformobileapp')
  },

  postActivateCode ({
    body,
  }: GeneralMutateRequest<{
    code: string
  }>): Response {
    // } & CaptchaPayload
    return axios.post('/v2/profile/code/apply', body)
  },

  deleteCode (body: GeneralMutateRequest<{ code: string }>): Response {
    return axios.post('/v2/profile/code/delete', body)
  },

  // ----- /Codes

  // ----- History
  getFinishedOrdersByAllPairs (
    params: ParsedUrlQueryInput,
  ): Response<Orderlist<RawOrder>> {
    return axios.get(`/v2/order/finished?${stringify(params)}`)
  },

  getOrdersByAllPairs (
    params: ParsedUrlQueryInput,
  ): Response<Orderlist<RawOrder>> {
    return axios.get(`/v2/order?${stringify(params)}`)
  },

  postOrder ({ body }: GeneralMutateRequest<PostOrderBody>): Response<any> {
    return axios.post('/v2/order', body)
  },

  postCrossMarginOrder ({ body }: GeneralMutateRequest<PostOrderBody>) {
    return axios.post('/v2/margin-order/put_limit', body)
  },

  deleteOrder ({ id, ...params }: ParsedUrlQueryInput): Response {
    return axios.delete(`/v2/order/${id}?${stringify(params)}`)
  },

  deleteAllPairOrders (pair = ''): Response {
    return axios.delete(`/v2/order/deleteall/${pair}`)
  },

  deleteAllCoinOrders (ticker: Ticker): Response {
    return axios.delete(`/v2/order/deleteallbycoin/${ticker}`)
  },

  getTrades (params: ParsedUrlQueryInput): Response<Orderlist<RawUserTrade>> {
    return axios.get(`/v2/apihistory?${stringify(params)}`)
  },

  putMarketOcoOrder ({ body }) {
    return axios.post('/v2/oco-order', body)
  },

  putMarketStopLimitOrder ({ body }) {
    return axios.post('/v2/stop-limit-order', body)
  },

  PutMarketOrder ({
    body,
  }: GeneralMutateRequest<PutMarketOrderRequest>): Response {
    return axios.post('/v2/order/put_market', body)
  },

  putMarketCrossStopLimitOrder ({
    body,
  }: GeneralMutateRequest<PutMarketOrderRequest>): Response {
    return axios.post('/v2/margin-order/stop-limit-order', body)
  },

  putMarketCrossOcoOrder ({
    body,
  }: GeneralMutateRequest<PutMarketOrderRequest>): Response {
    return axios.post('/v2/margin-order/oco-order', body)
  },

  putMarketCrossOrder ({
    body,
  }: GeneralMutateRequest<PutMarketOrderRequest>): Response {
    return axios.post('/v2/margin-order/put_market', body)
  },

  getDeposits (
    params: ParsedUrlQueryInput,
  ): Response<Orderlist<RawUserDepositWithdraw>> {
    return axios.get(`/v2/apideposit_history?${stringify(params)}`)
  },

  getDepositsByTicker ({
    ticker,
    ...params
  }: ParsedUrlQueryInput): Response<Orderlist<RawUserDepositWithdraw>> {
    return axios.get(`/v2/apideposit_history/${ticker}?${stringify(params)}`)
  },

  getWithdrawals (
    params: ParsedUrlQueryInput,
  ): Response<Orderlist<RawUserDepositWithdraw>> {
    return axios.get(`/v2/apiwithdraw_history?${stringify(params)}`)
  },

  getWithdrawalByTicker ({
    ticker,
    ...params
  }: ParsedUrlQueryInput): Response<Orderlist<RawUserDepositWithdraw>> {
    return axios.get(`/v2/apiwithdraw_history/${ticker}?${stringify(params)}`)
  },

  getHistoryByPair ({
    pair,
    ...params
  }: ParsedUrlQueryInput): Response<Orderlist<RawUserTrade>> {
    return axios.get(`/v2/apihistory/${pair}?${stringify(params)}`)
  },

  // ----- /History

  getMarketList () {
    if (!cache) return axios.get('/v2/market-list')
    return cache({ key: '/v2/market-list', expire: 60 }, () => {
      return axios.get('/v2/market-list')
    })
    // if (CACHE.has('/v2/market-list')) {
    //   return CACHE.get('/v2/market-list')
    // }
    // return axios.get('/v2/market-list')
    //   .then((res) => {
    //     CACHE.set('/v2/market-list', res)
    //     setTimeout(() => (CACHE.delete('/v2/market-list')), 1000 * 30)
    //     return res
    //   })
  },

  getFavoritePairs (): Response<PairName[]> {
    return axios.get('/v2/favorites')
  },

  postFavoritePair (params: ParsedUrlQueryInput): Response<any> {
    return axios.post(`/v2/favorites?${stringify(params)}`)
  },

  deleteFavoritePair ({ pair }: PostDeleteFavoritePairParams): Response<any> {
    return axios.delete(`/v2/favorites/${pair}`)
  },

  // ------ Fork battle -------

  getCurrentStageForkBattle (): Response<RawForkBattleResponse> {
    return axios.get('/v2/battle_of_forks/current_stage')
  },

  postVoteForCoin (coinId: number | null): Response<RawForkBattleResponse> {
    return axios.post(`/v2/battle_of_forks/create_vote/${coinId}`)
  },

  // ------- Latamex echange ---------

  getLatamexHistory (
    params: ParsedUrlQueryInput,
  ): Response<LatamexHistoryResponse> {
    return axios.get(`/v2/latamex-history?${stringify(params)}`)
  },

  getLatamexDetails (): Response<LatamexDataResponse> {
    return axios.get('/latamex-additional-data')
  },

  getLatamexCurrencyRate (
    params: ParsedUrlQueryInput,
  ): Response<LatamexCurrencyRate> {
    return axios.get(`/latamex-quotes?${stringify(params)}`)
  },

  sendLatamexFormRequest ({
    data,
  }): Response<{ [key in string]: string } & { redirectLink: string }> {
    return axios.post('/v2/balance/payment-latamex', data)
  },

  // ------- end Latamex --------

  /*
  $ping(params: FetchWsParams = []) {
      return wsService?.fetch({ method: "server.ping", params });
  }
  */
  /**
   * @deprecated to remove
   */
  getMarketTabs () {
    return axios.get('/marketlist')
  },

  // market.subscribe
  // market.unsubscribe
  // market.query

  /**
   * Subscribe and get market pairs once per 3s
   */
  $marketSubscribe (): Response<any> {
    return wsService?.fetch('market.subscribe')
  },

  /**
   * Unsubscribe from market
   */
  $marketUnsubscribe (): Response<any> {
    return wsService?.fetch('market.unsubscribe')
  },

  /**
   * Get market state without subscription
   */
  $marketQuery (): Response<any> {
    return wsService?.fetch('market.query')
  },

  $getDepth (params: GetDepthParsedParams): Response<GetDepthResponse> {
    const transformedParams = Object.values(params) as GetDepthParams

    transformedParams.splice(2, 0, '0')

    return wsService?.fetch('depth.query', transformedParams)
  },

  $subscribeDepth (params: GetDepthParsedParams): Response {
    const transformedParams = Object.values(params) as GetDepthParams

    transformedParams[2] = '0'

    return wsService?.fetch('depth.subscribe', transformedParams)
  },

  $unsubscribeDepth (): Response {
    return wsService?.fetch('depth.unsubscribe', [])
  },

  $subscribeToday (params: GetMarketParsedParams): Response {
    return wsService?.fetch('today.subscribe', params.pairs)
  },

  $unsubscribeToday (): Promise<any> {
    return wsService?.fetch('today.unsubscribe')
  },

  $subscribeDealsHistory (params: SubscribeDealsHistoryParams): Response {
    return wsService?.fetch('deals.subscribe', params)
  },

  $unsubscribeDealsHistory (): Response {
    return wsService?.fetch('deals.unsubscribe', [])
  },

  $getBars (params: GetBarsParsedParams): Response<GetBarsResponse> {
    const transformedParams = Object.values(params) as GetBarsParams

    return wsService?.fetch('kline.query', transformedParams)
  },

  $subscribeBars (params: SubscribeBarsParsedParams): Response {
    const transformedParams = Object.values(params) as SubscribeBarsParams

    return wsService?.fetch('kline.subscribe', transformedParams)
  },

  $unsubscribeBars (): Response {
    return wsService?.fetch('kline.unsubscribe')
  },

  $subscribeAssets ({ tickers }: SubscribeAssetsParsedParams): Response {
    return wsService?.fetch('asset.subscribe', tickers, true)
  },

  $unsubscribeAssets (): Response {
    return wsService?.fetch('asset.unsubscribe', [], true)
  },

  $getMarginTradeBalances (tickers: Ticker[] = []): Response<TradeBalanceHash> {
    return wsService?.fetch('asset_margin.query', tickers, true)
  },

  $subscribeMarginTradeAssets ({
    tickers,
  }: SubscribeAssetsParsedParams): Response {
    return wsService?.fetch('asset_margin.subscribe', tickers, true)
  },

  $unsubscribeMarginTradeAssets (): Response {
    return wsService?.fetch('asset_margin.unsubscribe', [], true)
  },

  $getOrders ({
    pairName,
    offset,
    limit,
  }: GetOrdersParams): Response<Orderlist<RawOrder>> {
    return wsService?.fetch(
      'order.query',
      [pairName, offset || 0, limit || 100],
      true,
    )
  },

  $getFinishedOrders ({
    pair,
    offset,
    limit,
  }: GetFinishedOrdersParams): Response<Orderlist<RawOrder>> {
    return wsService?.fetch(
      'order.history',
      [pair || '', 0, 0, offset, limit],
      true,
    )
  },

  $getBalances (tickers: Ticker[] = []): Response<TradeBalanceHash> {
    return wsService?.fetch('asset.query', tickers, true)
  },

  $subscribeOrders (pairs: PairName[]): Response {
    return wsService?.fetch('order.subscribe', pairs, true)
  },

  $unsubscribeOrders (): Response {
    return wsService?.fetch('order.unsubscribe', [], true)
  },

  $getPrice (pair: PairName): Response<GetPriceResponse> {
    return wsService?.fetch('price.query', [pair])
  },

  //  $subscribePrice(params: GetPriceParsedParams): Response {
  //     return wsService?.fetch('price.subscribe', params.pairs) // or state.subscribe / lastPrice
  // }
  /*
  private transformParams(params: any): any[] {
      return Object.values(params) as any[];
  }
  */
})

export type TApiStructure = ReturnType<typeof createApi>
