<template>
  <exc-label
    v-if="label"
    purple
    circle
    v-bind="{ ...label, text: undefined, src: undefined, translateY: '0'}"
    class="exc-header-label-creator txt--8px txt--color-01 ml-8 bor--radius-02"
  >
    <template v-if="label && label.text">
      <span class="txt txt--8px txt--weight-700 mt-auto mb-auto ml-auto mr-auto">
        {{ label.text }}
      </span>
    </template>
    <img
      v-if="label && label.src"
      :src="label.src"
      alt=""
    >
  </exc-label>
</template>

<script>
import ExcLabel from '@/components/common/labels/ExcLabel.vue'

export default {
  name: 'ExcHeaderLabelCreator',
  components: {
    ExcLabel,
  },
  props: {
    label: {
      type: Object, // .text .src +label props
      required: true,
    },
  },
}
</script>

.<style lang="scss" scoped>
.exc-header-label-creator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
</style>
